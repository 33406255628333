import React, { VFC, createRef, useState, useEffect } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { SimpleText } from '../../../components/SimpleText'
import { Card } from '../../payment/Card'
import { BackButton } from '../../../components/BackButton/BackButton'
import Mutation from '../../../shared/mutation'
import { PaypalBtn } from '../../../shared/paypal-btn'
import Notifications from '../../../shared/notifications'
import { PAYPAL_URL } from '../../../const'
import { SimpleBox } from '../../../components/SimpleBox'
import Router from '../../../shared/router'
import { createPaymentIntentMutation } from '../../../graphql/checkout'

type Props = {
  onBackPressed: () => void
}

type CreatePaymentIntent = { createPaymentIntent: Hash }
type CreatePaymentSourceResult = { createPaymentSource: boolean }
type GeneratePaypalTokenResult = { generatePaypalToken: string }
type SupportedTypes = 'paypal' | 'card'

const Chargebee = require('chargebee')

const mutationCreatePaymentSource = `
mutation createPaymentSource($input: CreatePaymentSourceInput!) {
  createPaymentSource(input: $input)
}`

const mutationGeneratePaypalToken = `
mutation createPaymentSource($input: CreatePaymentSourceInput!) {
  createPaymentSource(input: $input)
}`

const PaymentMethodsAdd: VFC<Props> = ({ onBackPressed }) => {
  const createPaymentSourceMutation = new Mutation<CreatePaymentSourceResult>(mutationCreatePaymentSource)
  const createPaymentIntent = new Mutation<CreatePaymentIntent>(createPaymentIntentMutation)
  const paypalMutation = new Mutation<GeneratePaypalTokenResult>(mutationGeneratePaypalToken)

  const [loading, setLoading] = useState<boolean>(false)

  const [selectedTab, setSelectedTab] = useState<SupportedTypes>(Router.qs.paypal ? 'paypal' : 'card')
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true)
  const cardRef = createRef<any>()

  const submit = (token: string, type: SupportedTypes) => {
    createPaymentSourceMutation.exec({ input: { token, type }}).then(() => {
      setLoading(false)

      const result = createPaymentSourceMutation.data?.createPaymentSource
      if (result) {
        Notifications.success('Payment method was added')
        Router.updateHistory('/dashboard?payments=1')
        onBackPressed()
      } else {
        Notifications.error(createPaymentSourceMutation.error(), { timing: 8000 })
      }
    })
  }

  const handleCard = () => {
    const cardTokenizer = cardRef.current
    cardTokenizer.tokenize().then(async (result: { token: string }) => {
      const variables = { amount: 100, currency: 'USD' }
      const paymentIntent = (await createPaymentIntent.exec(variables)).data?.createPaymentIntent
      if (paymentIntent) {
        const threeDSHandler = await Chargebee.getInstance().load3DSHandler()
        threeDSHandler.setPaymentIntent(paymentIntent)

        await threeDSHandler.handleCardPayment({ cbToken: result.token })
        submit(paymentIntent.id as string, 'card')
      }
    }).catch(() => {
      Notifications.error('Unable to proceed with the provided card information.')
      setLoading(false)
    })
  }

  const handlePayPal = () => {
    paypalMutation.exec().then(() => {
      setLoading(false)
      const token = paypalMutation.data?.generatePaypalToken
      if(token) {
        window.location.href = `${PAYPAL_URL}${encodeURIComponent(token)}`
      } else {
        Notifications.error(paypalMutation.error(), { timing: 8000 })
      }
    })
  }

  const handleSubmit = () => {
    setLoading(true)
    selectedTab === 'card' ? handleCard() : handlePayPal()
  }

  useEffect(() => {
    if (Router.qs.paypal === 'canceled') {
      Notifications.warning('PayPal transaction was canceled', { timing: 8000 })
    } else if(Router.qs.paypal === 'completed') {
      setLoading(true)
      const paypalToken = Router.qs.token
      paypalToken && submit(paypalToken as string, 'paypal')
    }
  }, [Router.qs.paypal])

  return <>
    <Modal.Header className="payment-methods-header">
      <BackButton onBackPressed={onBackPressed} />
      <SimpleText size="large" weight="bold">Add payment method</SimpleText>
    </Modal.Header>
    <Modal.Content className="white">
      <div className="payment-methods-tabs">
        <a onClick={() => setSelectedTab('paypal')} className={selectedTab === 'paypal' ? 'active' : ''}>PayPal</a>
        <a onClick={() => setSelectedTab('card')} className={selectedTab === 'card' ? 'active' : ''}>Credit Card</a>
      </div>
      {selectedTab === 'card' && (
        <>
          <Card
            nameDisabled
            forwardRef={cardRef}
            onValidChanged={valid => setButtonDisabled(!valid)}
          />
          <br />
          <SimpleBox paddingTop="20px" display="flex" justifyContent="center">
            <Button
              disabled={buttonDisabled}
              onClick={handleSubmit}
              content="Add credit card"
              color="blue"
              size="huge"
              primary
              loading={loading}
            />
          </SimpleBox>
        </>
      )}
      {selectedTab === 'paypal' && (<PaypalBtn onClick={handleSubmit} loading={loading} />)}
    </Modal.Content>
  </>
}

export { PaymentMethodsAdd }
