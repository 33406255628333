import React from 'react'
import { SimpleBox } from '../../SimpleBox'
import { SimpleCard } from '../../SimpleCard'
import { SimpleText } from '../../SimpleText'
import { Points } from '../../Plans/components/Single/components'
import { Checkbox, Label } from 'semantic-ui-react'
import Breakdown from '../Breakdown'
import { Coupon } from '../../../utils/coupon'
import { Addon, Plan, PlanSellingPoint } from '../../../type'
import { Discount } from '../../../fragments/modal/payment/DiscountNew'
import { Feature } from '../../../types/checkout'
import { toMoneyWithoutCalculation } from '../../../shared/format'
const ShieldRedIcon = require('~assets/images/icons/shield-red.svg')
const AlertIcon = require('~assets/images/icons/icon-alert.svg')
const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')
interface SummaryCardProps {
  total: string;
  coupon?: Coupon;
  activateNow: boolean;
  plan?: Plan;
  extraUserAddon?: Addon;
  userCount: number;
  handleToggleAddUser: () => void;
  firstPlanId: string;
  handleCouponFound: (coupon: Coupon) => void;
  handleRemoveCoupon: () => void;
  selectedFeatures: Feature[];
  bienniallyFreeMonths: number;
  priceWithAddon?: number;
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  total,
  coupon,
  activateNow,
  plan,
  extraUserAddon,
  userCount,
  handleToggleAddUser,
  firstPlanId,
  handleCouponFound,
  handleRemoveCoupon,
  selectedFeatures,
  bienniallyFreeMonths,
  priceWithAddon,
}) => {
  const canAddUser = plan?.currencyCode === 'USD'
  const points: PlanSellingPoint[] = [
    { icon: PlanCheckMarkIcon, title: '30 Day Free Trial' },
    { icon: PlanCheckMarkIcon, title: '24/7 Helpful Support' },
    { icon: PlanCheckMarkIcon, title: 'Unlimited Devices' },
    {
      icon: PlanCheckMarkIcon,
      title: 'VPN',
      subtitle: 'Internet Security',
    },
    {
      icon: PlanCheckMarkIcon,
      title: `${userCount} User${userCount > 1 ? 's' : ''}`,
    },
  ]
  activateNow && points.shift()

  const [leftTitle, rightTitle] = (plan?.externalName || '').split(' / ')
  const period = { 1: 'month', 12: 'year', 24: '2 years' }[plan?.period || 12]
  const extraUserPrice = toMoneyWithoutCalculation(
    parseFloat(extraUserAddon?.price || '0'),
    {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }
  )

  return (
    <SimpleCard className="checkout-summary-card">
      <div className="first-block">
        <SimpleBox
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap={12}
        >
          <img src={ShieldRedIcon} alt="shield" width={42} />
          <SimpleBox
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap={4}
          >
            <SimpleText size="title3">{leftTitle}</SimpleText>
            {rightTitle && (
              <SimpleText size="title3" color="red">
                {rightTitle}
              </SimpleText>
            )}
          </SimpleBox>
        </SimpleBox>
        <Points
          items={points}
          secondary={true}
          footer={null}
          newCheckout={true}
        />
        {canAddUser && (
          <SimpleBox className="summary-card-extra-features">
            <SimpleText size="buttonText" color="grey">
              Protect Your Family
            </SimpleText>
            <SimpleBox
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              gap={4}
              marginTop={8}
            >
              <Checkbox
                toggle
                className="add-one-checkbox"
                onChange={handleToggleAddUser}
              />
              <SimpleText size="text5">
                Add another user for {extraUserPrice}/{period}
              </SimpleText>
              <SimpleBox
                display="flex"
                flexDirection="column"
                position="relative"
                className="alert-box"
              >
                <Label pointing="below" className="alert-label">
                  Add a friend or family member and they will get their own
                  login and access to all the same features at a special rate.
                </Label>
                <img src={AlertIcon} alt="alert" width={16} />
              </SimpleBox>
            </SimpleBox>
          </SimpleBox>
        )}
      </div>
      <div className="second-block">
        <Breakdown
          total={total}
          coupon={coupon}
          activateNow={activateNow}
          plan={plan}
          userCount={userCount}
          selectedFeatures={selectedFeatures}
          bienniallyFreeMonths={bienniallyFreeMonths}
          priceWithAddon={priceWithAddon}
        />
        <div className="discountForm">
          <Discount
            planId={firstPlanId}
            onCouponFound={handleCouponFound}
            onRemoveCoupon={handleRemoveCoupon}
          />
        </div>
      </div>
    </SimpleCard>
  )
}

export default SummaryCard
