import React, { RefObject, useEffect, useState, VFC } from 'react'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { Button } from 'semantic-ui-react'
import { emailRegex, PAYPAL_URL } from '../../const'
import { Coupon } from '../../utils/coupon'
import Mutation from '../../shared/mutation'
import Notifications from '../../shared/notifications'
import { resolveCurrency } from '../../shared/format'
import { CHARGEBEE_PAYMENT_SOURCE_TYPES } from '../../shared/types'
import { AppleBtn, GoogleBtn, PaypalBtn } from '../../shared/paypal-btn'
import Router from '../../shared/router'
import Session from '../../shared/storages/session'
import { CardToken, PaypalToken, Subscribe } from '../../type'
import { PaymentData } from './ICardProps'
import Cookies from 'universal-cookie'
import {
  mutationPaypalMutation,
  mutationSubscribeMutation,
  createPaymentIntentMutation,
} from '../../graphql/checkout'
import { useStripe } from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js'
import Decimal from 'decimal.js'

const Chargebee = require('chargebee')
const cookies = new Cookies()

const ZIP_ERROR_MATCHER = '27'
const ZIP_ERROR_MESSAGE =
  'Error: The ZIP code does not match that on file with your bank. Please contact your bank to verify your zip code.'

type CreatePaymentIntent = { createPaymentIntent: Hash }
type Props = {
  type?: CHARGEBEE_PAYMENT_SOURCE_TYPES;
  data: PaymentData;
  priceId: string;
  cardRef: RefObject<any>;
  captchaRef: RefObject<any>;
  coupon?: Coupon;
  disabled?: boolean;
  activateNow: boolean;
  promotion: boolean;
  paypalReturnPath: string;
  purchaseFinished?: () => void;
  trialEnabled?: boolean;
  checkoutSubscriptionId: string;
  priceIds: string[];
  price?: number;
  subscriptionId?: string;
  checkoutConfigId?: string;
  planDetail?: { period: number; periodUnit: number };
  handleSetShowGoogleApplePay: (show: {
    google: boolean;
    apple: boolean;
  }) => void;
};

const Submit: VFC<Props> = ({
  type = 'CARD',
  data: paymentData,
  priceId,
  cardRef,
  captchaRef,
  coupon,
  disabled,
  activateNow,
  promotion,
  paypalReturnPath,
  purchaseFinished,
  checkoutSubscriptionId,
  priceIds,
  price = 0,
  subscriptionId = '',
  checkoutConfigId = '',
  planDetail,
  handleSetShowGoogleApplePay,
}) => {
  const stripe = useStripe()

  const createPaymentIntent = new Mutation<CreatePaymentIntent>(createPaymentIntentMutation)
  const subscribeMutation = new Mutation<Subscribe>(mutationSubscribeMutation)
  const paypalMutation = new Mutation<PaypalToken>(mutationPaypalMutation)

  const [loading, setLoading] = useState<boolean>(false)
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()

  const payment = {
    currency: resolveCurrency().toLowerCase(),
    total: { label: 'Your Total', amount: Math.ceil(new Decimal(price).mul(100).toNumber()) },
  }

  // Create payment request
  useEffect(() => {
    stripe && setPaymentRequest(stripe.paymentRequest({ country: 'US', ...payment }))
  }, [stripe])

  // Update payment request
  useEffect(() => { paymentRequest?.update(payment) }, [price])

  // Check the availability of the Payment Request API
  useEffect(() => {
    paymentRequest?.canMakePayment().then(result => {
      result && handleSetShowGoogleApplePay({
        google: result.googlePay,
        apple: result.applePay,
      })
    })
  }, [paymentRequest])

  // After success, properly redirect to the page with the necessary parameters
  const successRedirect = (id: string) => {
    const params = new URLSearchParams({
      subscriptionId,
      checkoutConfigId,
      checkoutSubscriptionId: id,
    })

    const couponCode = (Router.qs.paypal && (Router.qs.paypal as string).split('|')[1]) as string ||
      (Router.qs.coupon as string) || coupon?.id || ''

    couponCode && params.append('coupon', couponCode)
    activateNow && params.append('activateNow', '1')

    if (planDetail) {
      params.append('period', planDetail.period.toString())
      params.append('periodUnit', planDetail.periodUnit.toString())
    }

    Router.redirect(`/new-welcome?${params}`)
  }

  // Perform the subscription mutation and handle response
  const submit = (extra: Hash) => {
    if (loading) {
      return
    }

    const input = {
      type,
      priceIds,
      activateNow,
      checkoutSubscriptionId,
      email: paymentData.email,
      pubId: cookies.get('pubId') || '',
      clickId: cookies.get('clickId') || '',
      affiliateId: cookies.get('affiliateId') || '',
      signupSource: location.pathname.split('/')[1],
      currency: resolveCurrency(),
      promotion,
      ...extra,
    }

    subscribeMutation.exec({ input }).then(action(() => {
      const result = subscribeMutation.data?.result
      if (result) {
        Notifications.success('Account created successfully')
        Session.authenticated = true
        Session.accessToken = result.token
        purchaseFinished && purchaseFinished()
        successRedirect(result.subscriptionId)
      } else {
        setLoading(false)
        const error = subscribeMutation.error()
        const message = error.includes(ZIP_ERROR_MATCHER) ? ZIP_ERROR_MESSAGE : error
        Notifications.error(message, { timing: 8000 })
      }
    }))
  }

  // Submit from a payload that contains a token
  const submitWithToken = (result: CardToken) => {
    submit({
      zip: result.zip || paymentData.zip,
      firstName: result.firstName || paymentData.firstName,
      lastName: result.lastName || paymentData.lastName,
      couponCode: coupon?.id || '',
      [result.wallet]: { token: result.token },
    })
  }

  // Submit PayPal token and coupon code
  const submitPayPal = (token: string, couponCode?: string) => {
    submit({
      zip: '0000',
      couponCode: coupon?.id || couponCode || '',
      paypal: { token },
    })
  }

  // Handle the proper payment flow with Chargebee.js token generation with 3DS
  const handleChargebeeJs = () => {
    const cardTokenizer = cardRef.current
    cardTokenizer.tokenize().then(async (result: { token: string }) => {
      const variables = { amount: payment.total.amount, currency: payment.currency }
      const paymentIntent = (await createPaymentIntent.exec(variables)).data?.createPaymentIntent
      if (paymentIntent) {
        const threeDSHandler = await Chargebee.getInstance().load3DSHandler()
        threeDSHandler.setPaymentIntent(paymentIntent)

        await threeDSHandler.handleCardPayment({ cbToken: result.token })
        submitWithToken({ wallet: 'card', token: (paymentIntent.id as string) })
      }
    }).catch(() => {
      Notifications.error('Unable to proceed with the provided card information.')
      setLoading(false)
    })
  }

  // Handle submit with captcha capturing
  const handleSubmit = () => {
    if (loading) {
      return
    }

    const captchaTokenizer = captchaRef.current
    captchaTokenizer.executeAsync().then((token: string) => {
      captchaTokenizer.reset()
      if (token && type === 'CARD') {
        setLoading(true)
        handleChargebeeJs()
      }
    })
  }

  // Handle the PayPal payment preparation flow
  const handlePayPal = () => {
    setLoading(true)
    const payload = {
      id: priceId,
      activateNow,
      email: paymentData.email,
      coupon: coupon?.name || '',
      returnPath: paypalReturnPath,
    }

    paypalMutation.exec(payload).then(() => {
      const token = paypalMutation.data?.token
      if (token) {
        window.location.href = `${PAYPAL_URL}${token}`
      } else {
        setLoading(false)
        Notifications.error(paypalMutation.error(), { timing: 8000 })
      }
    })
  }

  const handleCreateStripePayment = async () => {
    setLoading(true)
    paymentRequest?.show()
    setLoading(false)
  }

  // Check for PayPal return path processing
  useEffect(() => {
    if (!Router.qs.paypal || (!coupon && !!Router.qs.coupon)) {
      return
    }

    if (loading) {
      return
    }

    const result = (Router.qs.paypal as string).split('|')[3] as string
    const couponCode = (Router.qs.paypal as string).split('|')[1] as string
    if (result === 'completed') {
      setLoading(true)
      submitPayPal(Router.qs.token as string, couponCode)
    } else {
      Notifications.warning('The operation on PayPal was canceled.')
    }
  }, [coupon])

  const payMethodReady = emailRegex.test(paymentData?.email || '')
  const cardReady = [
    payMethodReady,
    paymentData?.zip?.length,
    paymentData?.firstName?.length,
    paymentData?.lastName?.length,
  ].every(Boolean)

  if (type === 'CARD') {
    return (
      <Button
        disabled={!cardReady || disabled}
        onClick={handleSubmit}
        content={activateNow ? 'Continue' : 'Start Free Trial'}
        color="red"
        size="huge"
        primary
        fluid
        loading={loading}
      />
    )
  }

  const Component = {
    PAYPAL: PaypalBtn,
    APPLE_PAY: AppleBtn,
    GOOGLE_PAY: GoogleBtn,
  }[type]

  return (
    <Component
      disabled={!payMethodReady || disabled}
      onClick={type === 'PAYPAL' ? handlePayPal : handleCreateStripePayment}
      loading={loading}
    />
  )
}

const oSubmit = observer(Submit)
export { oSubmit as Submit }
