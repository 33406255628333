import { action } from 'mobx'
import { observer } from 'mobx-react'
import React, { RefObject, useEffect, useState, VFC } from 'react'
import { Button } from 'semantic-ui-react'
import { emailRegex, PAYPAL_URL } from '../../const'
import { Coupon } from '../../utils/coupon'
import Mutation from '../../shared/mutation'
import Notifications from '../../shared/notifications'
import { PaypalBtn } from '../../shared/paypal-btn'
import Router from '../../shared/router'
import Session from '../../shared/storages/session'
import { PaypalToken } from '../../type'
import { PaymentData } from './ICardProps'
import { resolveCurrency } from '../../shared/format'
import Cookies from 'universal-cookie'
import {
  mutationPaypalMutation,
  mutationSubscribeMutation,
  createPaymentIntentMutation,
} from '../../graphql/checkout'

type Subscribe = { result: { token: string, subscriptionId: string, finalPrice: number } }

const Chargebee = require('chargebee')
const cookies = new Cookies()

const ZIP_ERROR_MATCHER = '27'
const ZIP_ERROR_MESSAGE = 'Error: The ZIP code does not match that on file with your bank. Please contact your bank to verify your zip code.'

type CreatePaymentIntent = { createPaymentIntent: Hash }
type Props = {
  type: 'CARD' | 'PAYPAL'
  data: PaymentData
  price: number
  priceId: string
  cardRef: RefObject<any>
  captchaRef: RefObject<any>
  coupon?: Coupon
  disabled?: boolean
  activateNow: boolean
  promotion: boolean
  paypalReturnPath: string
  purchaseFinished?: () => void
}

const Submit: VFC<Props> = ({
  type,
  data: paymentData,
  price,
  priceId,
  cardRef,
  captchaRef,
  coupon,
  disabled,
  activateNow,
  promotion,
  paypalReturnPath,
  purchaseFinished
}) => {
  const createPaymentIntent = new Mutation<CreatePaymentIntent>(createPaymentIntentMutation)
  const subscribeMutation = new Mutation<Subscribe>(mutationSubscribeMutation)
  const paypalMutation = new Mutation<PaypalToken>(mutationPaypalMutation)

  const [loading, setLoading] = useState<boolean>(false)

  // After success, properly redirect to the page with the necessary parameters
  const successRedirect = () => {
    const params = new URLSearchParams()
    const couponCode = (Router.qs.paypal && (Router.qs.paypal as string).split('|')[1]) as string ||
      (Router.qs.coupon as string) || coupon?.id || ''

    couponCode && params.append('coupon', couponCode)
    activateNow && params.append('activateNow', '1')

    Router.redirect(`/welcome?${params}`)
  }

  // Perform the subscription mutation and handle response
  const submit = (extra: Hash) => {
    if (loading) {
      return
    }

    const input = {
      type,
      priceId,
      activateNow,
      email: paymentData.email,
      pubId: cookies.get('pubId') || '',
      clickId: cookies.get('clickId') || '',
      affiliateId: cookies.get('affiliateId') || '',
      signupSource: location.pathname.split('/')[1],
      currency: resolveCurrency(),
      promotion,
      ...extra,
    }

    subscribeMutation.exec({ input }).then(action(() => {
      const result = subscribeMutation.data?.result
      if (result) {
        Notifications.success('Account created successfully')
        Session.authenticated = true
        Session.accessToken = result.token
        purchaseFinished && purchaseFinished()
        localStorage.removeItem('vs_checkout_cache')
        successRedirect()
      } else {
        setLoading(false)
        const error = subscribeMutation.error()
        const message = error.includes(ZIP_ERROR_MATCHER) ? ZIP_ERROR_MESSAGE : error
        Notifications.error(message, { timing: 8000 })
      }
    }))
  }

  // Submit from a payload that contains a token
  const submitWithToken = (token: string) => {
    submit({
      zip: paymentData.zip,
      firstName: paymentData.firstName,
      lastName: paymentData.lastName,
      couponCode: coupon?.id || '',
      card: { token },
    })
  }

  // Submit PayPal token and coupon code
  const submitPayPal = (token: string, couponCode?: string) => {
    submit({
      zip: '0000',
      couponCode: coupon?.id || couponCode || '',
      paypal: { token },
    })
  }

  // Handle the proper payment flow with Chargebee.js token generation with 3DS
  const handleChargebeeJs = () => {
    const cardTokenizer = cardRef.current
    cardTokenizer.tokenize().then(async (result: { token: string }) => {
      const variables = { amount: Math.ceil(price * 100), currency: resolveCurrency() }
      const paymentIntent = (await createPaymentIntent.exec(variables)).data?.createPaymentIntent
      if (paymentIntent) {
        const threeDSHandler = await Chargebee.getInstance().load3DSHandler()
        threeDSHandler.setPaymentIntent(paymentIntent)

        await threeDSHandler.handleCardPayment({ cbToken: result.token })
        submitWithToken((paymentIntent.id as string))
      }
    }).catch(() => {
      Notifications.error('Unable to proceed with the provided card information.')
      setLoading(false)
    })
  }

  // Handle submit with captcha capturing
  const handleSubmit = () => {
    if (loading) {
      return
    }

    const captchaTokenizer = captchaRef.current
    captchaTokenizer.executeAsync().then((token: string) => {
      captchaTokenizer.reset()
      if (token && type === 'CARD') {
        setLoading(true)
        handleChargebeeJs()
      }
    })
  }

  // Handle the PayPal payment preparation flow
  const handlePayPal = () => {
    setLoading(true)
    const payload = {
      id: priceId,
      activateNow,
      email: paymentData.email,
      coupon: coupon?.name || '',
      returnPath: paypalReturnPath,
    }

    paypalMutation.exec(payload).then(() => {
      const token = paypalMutation.data?.token
      if (token) {
        window.location.href = `${PAYPAL_URL}${token}`
      } else {
        setLoading(false)
        Notifications.error(paypalMutation.error(), { timing: 8000 })
      }
    })
  }

  // Check for PayPal return path processing
  useEffect(() => {
    if (!Router.qs.paypal || (!coupon && !!Router.qs.coupon)) {
      return
    }

    if (loading) {
      return
    }

    const result = (Router.qs.paypal as string).split('|')[3] as string
    const couponCode = (Router.qs.paypal as string).split('|')[1] as string
    if (result === 'completed') {
      setLoading(true)
      submitPayPal(Router.qs.token as string, couponCode)
    } else {
      Notifications.warning('The operation on PayPal was canceled.')
    }
  }, [coupon])

  const isCard = type === 'CARD'
  const payMethodReady = emailRegex.test(paymentData?.email || '')
  const cardReady = [
    payMethodReady,
    paymentData?.zip?.length,
    paymentData?.firstName?.length,
    paymentData?.lastName?.length,
  ].every(Boolean)

  return (isCard ? (
    <Button
      disabled={!cardReady || disabled}
      onClick={handleSubmit}
      content={activateNow ? 'Continue' : 'Start Free Trial'}
      color="red"
      size="huge"
      primary
      fluid
      loading={loading}
    />
  ) : (
    <PaypalBtn
      disabled={!payMethodReady || disabled}
      onClick={handlePayPal}
      loading={loading}
    />
  ))
}

const oSubmit = observer(Submit)
export { oSubmit as Submit }
