import { gql } from '@apollo/client'

export interface IExtraFeature {
  id: string;
  title: string;
  description: string;
  price: number;
  imageUrl: string;
  chargebeeId: string;
  position: number;
}

export const queryPlan = gql`
  query ($id: ID!) {
    plan(id: $id) {
      id
      name
      externalName
      description
      price
      period
      periodUnit
      pricingModel
      currencyCode
      trialPeriod
      trialPeriodUnit
      billingCycles
      freeQuantity
      itemFamily {
        name
      }
      item {
        metadata
      }
      __typename
    }
  }
`

export const queryCheckoutUpsellItems = gql`
  query checkoutConfiguration($id: ID!) {
    checkoutConfiguration(id: $id) {
      cartExtraFeatures {
        id
        title
        description
        imageUrl
        chargebeeId
      }
    }
  }
`

export const queryCheckoutUpsells = gql`
  query useCheckoutConfigurationUpsell($checkoutSubscriptionId: ID!) {
    useCheckoutConfigurationUpsell(
      checkoutSubscriptionId: $checkoutSubscriptionId
    ) {
      subscriptionId
      availableUpsells
    }
  }
`

export const mutationSubscribeMutation = `
mutation($input: SubscribeInput!) {
  result: subscribe(input: $input) { token subscriptionId finalPrice }
}`

export const mutationPaypalMutation = `
mutation($id: ID!, $email: String!, $activateNow: Boolean, $coupon: String, $returnPath: String) {
  token: paypalToken(id: $id, email: $email, activateNow: $activateNow, coupon: $coupon, returnPath: $returnPath)
}`

export const createPaymentIntentMutation = `
mutation($amount: Int!, $currency: String) {
  createPaymentIntent(amount: $amount, currency: $currency)
}`

export const attachAddon = gql`
  mutation($subscription: ID!, $id: ID!, $coupon: string) {
    attachAddon(subscription: $subscription, id: $id, coupon: $coupon)
  }
`
export const applyCoupon = gql`
  mutation($code: String!, $priceId: ID) {
    coupon: redeemCoupon(code: $code, itemPriceId: $priceId) {
      id
      name
      discountType
      discountPercentage
      discountAmount
    }
  }
`
export const getCouponDetail = gql`
  mutation($code: String!) {
    coupon: redeemCoupon(code: $code) {
      id
      name
      discountType
      discountPercentage
      discountAmount
    }
  }
`

export const createCheckoutSubscription = gql`
  mutation($affiliateId: ID) {
    createCheckoutSubscription(affiliateId: $affiliateId) {
      id
      checkoutConfiguration {
        id
        title
        template
        freeMonths
        defaultCoupon
        payNowEnabled
        checkoutSteps {
          id
          stepType
          position
        }
        cartSteps {
          id
          stepType
          position
        }
        cartExtraFeatures {
          id
          title
          description
          imageUrl
          chargebeeId
        }
        cartPaymentMethods {
          id
          paymentMethod
          position
        }
      }
    }
  }
`

export const queryUpsellItems = gql`
  query addons($periodUnit: Integer, $period: Integer, $currency: String) {
    addons(periodUnit: $periodUnit, period: $period, currency: $currency) {
      id
      name: externalName
      itemId
      period
      periodUnit
      price
    }
  }
`
